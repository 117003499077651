import { ColorScheme } from "@mantine/core";

export type Activity = {
  time: number;
  date: string;
};

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber?: string;
  dob?: string;
  country?: string;
  city?: string;
  zip?: number;
  lastActivity: string;
  inscriptionDate: string;
  role: UserRoles;
  status: UserStatus;
  email: string;
  xp: number;
  balance: number;
  affiliatedUsers: User[] & string[];
  generation: { _id: string; number: number };
  note?: string;
  level?: number;
  parent?: User | string; // Could be just a string if it s just the id. Don't know how old dev handle this (sometimes it's a string, sometimes it's an object with relations)
  transactionCount?: number; // computed field from backend because DLC needs it.
};

export enum UserRoles {
  ADMIN = "ADMIN",
  STAFF = "STAFF",
  USER = "USER",
  BOUNCER = "BOUNCER",
  CASHIER = "CASHIER",
  MERCHANT = "MERCHANT",
}

export const UserRolesLabel = {
  [UserRoles.ADMIN]: "Administrateur",
  [UserRoles.STAFF]: "Bar",
  [UserRoles.USER]: "Utilisateur",
  [UserRoles.BOUNCER]: "Videur",
  [UserRoles.CASHIER]: "Caissier",
  [UserRoles.MERCHANT]: "Marchand",
};

export enum UserStatus {
  ON = "ON",
  OFF = "OFF",
  BAN = "BAN",
}

export type UserWarning = {
  user: string;
  acknowledgedAt: Date | null;
  createdAt: Date;
};

export type Transaction = {
  _id: string;
  type: TransactionType;
  date: string;
  description: string;
  points: number;
  xp?: number;
  from?: User;
  to: User;
  actionId?: string;
  product?: Product;
  eventId?: string;
};

export enum TransactionType {
  ACTION = "ACTION",
  EVENT = "EVENT",
  PRODUCT = "PRODUCT",
}

export type Product = {
  _id: string;
  id?: number;
  title: string;
  sponsor: string;
  partner?: Partner;
  price: string;
  points: number;
  xp?: number;
  start: string;
  end: string;
  used: number;
  stock: number;
  status: ProductStatus;
  imageUrl: string;
  description?: string;
  category: ProductCategory;
  specialOffer?: {
    isActive: boolean;
    seenBy: number[];
  };
};

export enum ProductStatus {
  ON = "ON",
  DRAFT = "DRAFT",
}

export type ProductCategory = {
  id: string;
  label: string;
};

export type Event = {
  _id: string;
  date: string;
  title: string;
  place: string;
  imageUrl: string;
  points: number;
  price: number;
  participants: { firstName: string; lastName: string; username: string }[];
  status: EventStatus;
  description?: string;
};

export enum EventStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
}

export type Action = {
  _id: string;
  title: string;
  operationType: OperationType;
  points: number;
  xp: number;
  status: ActionStatus;
  description?: string;
  type: ActionTypeType;
};

export enum ActionStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum OperationType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export enum ActionTypeType {
  SYSTEM = "SYSTEM",
  EXTERNAL = "EXTERNAL",
}

export type Config = {
  isDisabled: boolean;
};

export type Partner = {
  _id: string;
  name: string;
  users: User[];
  status: PartnerStatus;
};

export enum PartnerStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
